<template>
  <Container>
    <Notice
      :headline="headline"
      :headline-level="headlineLevel"
      :headline-size="headlineSize"
      :description="description"
      :type="type"
    />
  </Container>
</template>

<script>
  import { AlchemyElement } from "~/mixins/alchemyVue"

  export default {
    mixins: [AlchemyElement],
    computed: {
      headline() {
        return this.getValue("headline")
      },
      headlineLevel() {
        return this.getIngredient("headline")?.level || 3
      },
      type() {
        return this.getValue("type")
      },
      description() {
        return this.getRichtext("description")
      },
    },
  }
</script>
