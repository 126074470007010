<template>
  <div :class="['notice', `notice-${type}`]">
    <div class="notice-content">
      <component :is="ICON_TYPE_MAP[type]" class="notice-icon" />
      <Headline
        :size="headlineSize"
        :level="headlineLevel"
        class="notice-headline"
      >
        {{ headline }}
      </Headline>
      <RichText class="notice-paragraph" :content="description" />
    </div>
  </div>
</template>

<script>
  import Headline from "~/components/Headline"
  import RichText from "~/components/RichText"
  import InfoIcon from "~/assets/sprite-icons/info.svg?component"
  import ErrorIcon from "~/assets/sprite-icons/alert-circle.svg?component"
  import WarningIcon from "~/assets/sprite-icons/alert-triangle.svg?component"

  const ICON_TYPE_MAP = {
    info: InfoIcon,
    error: ErrorIcon,
    warning: WarningIcon,
  }

  export default {
    components: { Headline, RichText },
    props: {
      headline: {
        type: String,
        default: "",
      },
      description: {
        type: String,
        default: "",
      },
      headlineLevel: {
        type: Number,
        default: 3,
      },
      headlineSize: {
        type: Number,
        default: 3,
      },
      type: {
        type: String,
        default: "info",
        validator(value) {
          return ["info", "error", "warning"].includes(value)
        },
      },
    },
    data() {
      return { ICON_TYPE_MAP }
    },
  }
</script>

<style lang="scss" scoped>
  @import "assets/styles";

  .notice {
    display: flex;
    justify-content: center;
    padding: $space-m $space-m;
    border-radius: $border-radius;
    @include viewport("sm", "mini") {
      padding: $space-m $space-s;
    }

    &-info {
      border: 1px solid $blue-300;
      .notice-headline,
      .notice-icon {
        color: $blue-text;
      }
    }
    &-warning {
      border: 1px solid $yellow-300;
      .notice-headline,
      .notice-icon {
        color: $yellow-text;
      }
    }
    &-error {
      border: 1px solid $red-300;
      .notice-headline,
      .notice-icon {
        color: $red-text;
      }
    }
  }
  .notice-icon {
    width: $space-m;
    height: $space-m;
    margin-bottom: $space-s;
  }
  .notice-content {
    max-width: $space-l * 8;
    @include viewport("sm", "mini") {
      max-width: $space-l * 5;
      .notice-headline {
        @apply h4;
      }
      .notice-paragraph {
        @apply text-sm leading-normal;
      }
    }
  }
</style>

<style lang="scss">
  @import "assets/styles";

  .notice-info {
    a {
      color: $blue-text;
    }
  }
  .notice-warning {
    a {
      color: $yellow-text;
    }
  }
  .notice-error {
    a {
      color: $red-text;
    }
  }
</style>
